<template>
  
    <div>
      <div class="row" v-if="computedLabels.title">
        <div class="col-lg-12">
          <h3>{{ getLocalizedText(computedLabels.title) }}</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3"></div>
        <div class="col-lg-9">
          <div class="row">
            <div class="col-lg-6 text-center p-xs" v-if="productionPicturePath">
              <div v-if="computedLabels.production">{{ getLocalizedText(computedLabels.production) }}</div>
              <img :src="getStaticURLFromPath(productionPicturePath)" height="50px" />
            </div>
            <div :class="(productionPicturePath ? 'col-lg-6' : 'col-lg-12') + ' text-center p-xs'" v-if="picturePath">
              <div v-if="computedLabels.validation">{{ getLocalizedText(computedLabels.validation) }}</div>
              <img :src="getStaticURLFromPath(picturePath)" height="50px" />
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label for="updateLogo" class="col-lg-3 col-form-label">{{ getLocalizedText(computedLabels.label) }}</label>
        <div class="col-lg-9">
          <div :class="{'input-group' : ($slots.buttons != undefined)}">
            <b-form-file
              v-model="form.picture"
              :placeholder="getLocalizedText(computedLabels.placeholder)"
              :drop-placeholder="getLocalizedText(computedLabels.drop_placeholder)"
            />
            <span class="input-group-append" v-if="$slots.buttons"> 
              <slot name="buttons"></slot>
            </span>
          </div>
        </div>
      </div>
    </div>

</template>

<style>

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsContent, CmsEnum, CmsLabel, CmsPicture, CmsText, CmsFile, CmsNumber } from '@fwk-client/modules/cms/types';

/** @cmsLabels */
export interface PictureLabels {
  /** @cmsType CmsLabel */
  title?:CmsLabel;
  /** @cmsType CmsLabel */
  label?:CmsLabel;
  /** @cmsType CmsLabel */
  production?:CmsLabel;
  /** @cmsType CmsLabel */
  validation?:CmsLabel;
  /** @cmsType CmsLabel */
  placeholder?:CmsLabel;
  /** @cmsType CmsLabel */
  drop_placeholder?:CmsLabel;
}

export default defineComponent({
  props: {
      picture: {
        type: [Object,String] as PropType<any|String>,
        required: false,
        default: null
        
      },
      productionPicture: {
        type: Object,
        required: false,
        default: null
      },
      labels: {
        type: Object as PropType<PictureLabels>,
        default: () => { return {} }
      },
      components: Object as PropType<PictureSlots>
  },
  components: {
    
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { picture, productionPicture } = toRefs(props);

    const picturePath = computed(() => {
      if(picture.value && picture.value.original) {
        return picture.value.original.path;
      }
      else if(typeof picture.value == "string") {
        return picture.value;
      }
      return null;
    })

    const productionPicturePath = computed(() => {
      if(productionPicture.value && productionPicture.value.original) {
        return productionPicture.value.original.path;
      }
      else if(typeof productionPicture.value == "string") {
        return productionPicture.value;
      }
      return null;
    })

    const computedLabels:PictureLabels = {
      "placeholder" : {
        "fr" : "Image",
        "en" : "Picture"
      },
      "drop_placeholder" : {
        "fr" : "Image",
        "en" : "Picture"
      },
      ...props.labels
    }

    const form:any = reactive({
      picture : null
    });

    watch(
      form,
      (val:any, oldVal:any) => {
        context.emit('update:value', form.picture);
      },
      { deep: true }
    )

    watch(
      picture,
      (val:any, oldVal:any) => {
        form.picture = null;
      },
      { deep: true }
    )

    return {
      picture,
      productionPicture,
      form,
      computedLabels,
      picturePath,
      productionPicturePath
    }

  }
})
</script>