<template>
  
    <form role="form" @submit="onFormSubmit" name="update-theme-site">
      <div v-if="isLogoAndBannerAllowed">
        <Picture 
          v-bind:value.sync="form.logo" 
          :picture="logo" 
          :productionPicture="productionLogo" 
          :labels="{ 
            title:$t('cms.site.logo-and-banner.logo'), 
            label:$t('cms.site.info.logo'),
            production:$t('cms.site.update.production'),
            validation:$t('cms.site.update.validation'),
            placeholder:$t('cms.site.info.logo_placeholder'),
            drop_placeholder:$t('cms.site.info.logo_drop-placeholder')
          }"
        />
        <Picture 
          v-bind:value.sync="form.banner" 
          :picture="banner" 
          :productionPicture="productionBanner" 
          :labels="{ 
            title:$t('cms.site.logo-and-banner.banner'), 
            label:$t('cms.site.info.banner'),
            production:$t('cms.site.update.production'),
            validation:$t('cms.site.update.validation'),
            placeholder:$t('cms.site.info.logo_placeholder'),
            drop_placeholder:$t('cms.site.info.logo_drop-placeholder')
          }"
        />
        <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{$t('cms.site.logo-and-banner.button')}}</button>
      </div>
    </form>

</template>

<style>

</style>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import Picture from '@fwk-client/components/panels/input/Picture.vue'
import * as Ladda from 'ladda';
import * as api from '@fwk-client/utils/api';

import { useVigneronOnlineAdmin } from '../../../../composables/useVigneronOnlineAdmin';

export default defineComponent({
  props: {
    
  },
  components: {
    Picture
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { selectedShopContent, updateShopContentTheme } = useVigneronOnlineAdmin(props, context);

    const logo = computed(() => {
      if(selectedShopContent.value && selectedShopContent.value.shopContent.logo) { return selectedShopContent.value.shopContent.logo; }
      return null;
    })

    const productionLogo = computed(() => {
      if(selectedShopContent.value && selectedShopContent.value.productionShopContent && selectedShopContent.value.productionShopContent.logo) { return selectedShopContent.value.productionShopContent.logo; }
      return null;
    })

    const banner = computed(() => {
      if(selectedShopContent.value && selectedShopContent.value.shopContent.banner) { return selectedShopContent.value.shopContent.banner; }
      return null;
    })

    const productionBanner = computed(() => {
      if(selectedShopContent.value && selectedShopContent.value.productionShopContent && selectedShopContent.value.productionShopContent.banner) { return selectedShopContent.value.productionShopContent.banner; }
      return null;
    })

    const form:any = reactive({
      logo : null,
      banner : null,
    })

    var laddaSubmit:Ladda.LaddaButton|null = null;

    const isLogoAndBannerAllowed = computed(() => {
      return selectedShopContent && selectedShopContent.value.options.isShopContentAdminAllowed;
    })

    onMounted(() => {
      if(isLogoAndBannerAllowed.value) {
        var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-theme-site] button.update' );
        laddaSubmit = Ladda.create(submitButton!);
      }
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();

      updateShopContentTheme(form).then((result:any) => {
        if(result.updated && result.shopContent) {  
          // We emit event as user is updated
          context.emit('shop-content-updated', result.shopContent);
        }
        laddaSubmit!.stop();
      })
    }

    return {
      logo,
      productionLogo,
      banner,
      productionBanner,
      isLogoAndBannerAllowed,
      form,
      onFormSubmit
    }
  }
})
</script>