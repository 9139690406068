<template>
    <div class="row m-b-lg m-t-lg">
        <div class="col-md-6">
          <div v-if="logoURL" class="profile-image">
              <img :src="logoURL" class="rounded-circle circle-border" alt="logo">
          </div>
          <div :class="(logoURL ? 'profile-info' : '')">
            <h2 class="no-margins">{{ name }}</h2>
            <p>
              <span v-if="selectedShopContent">{{$t('vigneron-online.shop-content.info.path')}} <a :href="siteLink" target="_blank">{{ code }}</a><br/></span>
              {{$t('cms.info.company')}} {{ companyName }}<br/>
            </p>
          </div>
        </div>
        <div class="col-md-6">
            <ShopContentSwitch v-if="shopContents.length > 1"></ShopContentSwitch>
        </div>
        <div class="col-md-12" v-if="shopContentValidationLink">
          <a class="btn btn-primary" :href="shopContentValidationLink" target="_blank">{{$t('vigneron-online.deployment.validation.button-shop-content')}}</a>
        </div>
      </div>
</template>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { languagesTypes } from '@fwk-client/store/types';
import ShopContentSwitch from './ShopContentSwitch.vue';

import { useVigneronOnlineAdmin } from '../../composables/useVigneronOnlineAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      ShopContentSwitch
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedShopContent, shopContents, getShopContentValidationLink } = useVigneronOnlineAdmin(props, context);

      const logoURL = null;

      const name = computed(() => {
        if(selectedShopContent && selectedShopContent.value.shopContent.shop) { return selectedShopContent.value.shopContent.shop.name; }
        return "";
      })

      const code = computed(() => {
        if(selectedShopContent && selectedShopContent.value.shopContent.path) { return selectedShopContent.value.shopContent.path; }
        return "-";
      })

      const companyName = computed(() => {
        if(selectedShopContent && selectedShopContent.value.shopContent.shop && selectedShopContent.value.shopContent.shop.company) { return selectedShopContent.value.shopContent.shop.company.name; }
        return "-";
      })

      const id = computed(() => {
        if(selectedShopContent) { return selectedShopContent.value.shopContent._id; }
        return "-";
      })

      const siteLink= computed(() => {
        var currentLanguageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
        if(selectedShopContent) {
          return "https://www.vigneron-online.fr/"+currentLanguageCode+"/"+selectedShopContent.value.shopContent.path;
        }
        return "javascript:void(0)";
      })

       const shopContentValidationLink = computed(() => {
        return getShopContentValidationLink(selectedShopContent.value.shopContent);
      })

      return { 
        selectedShopContent,
        logoURL,
        name,
        code,
        companyName,
        id,
        siteLink,
        shopContents,
        shopContentValidationLink
      }
    }
})
</script>