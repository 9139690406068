var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('form',{attrs:{"role":"form","name":"update-theme-site"},on:{"submit":_vm.onFormSubmit}},[(_vm.isLogoAndBannerAllowed)?_c('div',[_c('Picture',{attrs:{"value":_vm.form.logo,"picture":_vm.logo,"productionPicture":_vm.productionLogo,"labels":{ 
        title:_vm.$t('cms.site.logo-and-banner.logo'), 
        label:_vm.$t('cms.site.info.logo'),
        production:_vm.$t('cms.site.update.production'),
        validation:_vm.$t('cms.site.update.validation'),
        placeholder:_vm.$t('cms.site.info.logo_placeholder'),
        drop_placeholder:_vm.$t('cms.site.info.logo_drop-placeholder')
      }},on:{"update:value":function($event){return _vm.$set(_vm.form, "logo", $event)}}}),_vm._v(" "),_c('Picture',{attrs:{"value":_vm.form.banner,"picture":_vm.banner,"productionPicture":_vm.productionBanner,"labels":{ 
        title:_vm.$t('cms.site.logo-and-banner.banner'), 
        label:_vm.$t('cms.site.info.banner'),
        production:_vm.$t('cms.site.update.production'),
        validation:_vm.$t('cms.site.update.validation'),
        placeholder:_vm.$t('cms.site.info.logo_placeholder'),
        drop_placeholder:_vm.$t('cms.site.info.logo_drop-placeholder')
      }},on:{"update:value":function($event){return _vm.$set(_vm.form, "banner", $event)}}}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary ladda-button update",attrs:{"data-style":"zoom-in","type":"submit"}},[_vm._v(_vm._s(_vm.$t('cms.site.logo-and-banner.button')))])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }