<template>
  <section id="shop" ref="shopContent">
    <PageTitle :title="$t('vigneron-online.shop-content.theme.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedShopContent && selectedShopContent.options.isShopContentAdminAllowed">
        <div class="col">
          <ThemeBox></ThemeBox>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';
import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

import { useVigneronOnlineAdmin } from '../../composables/useVigneronOnlineAdmin';

import ThemeBox from '../panels/theme/Theme.vue';
import Header from '../panels/Header.vue';

@Component({
  components: {
    PageTitle,
    ThemeBox,
    Header
  }
})
export default class Theme extends mixins(GenericPage) {

  setup(props:any, context:any) {
    const { shopContents, selectedShopContent } = useVigneronOnlineAdmin(props, context);
    return { shopContents, selectedShopContent }
  }

  get breadcrumbs() {
    return [{
      label: this.$t('home.dashboard')
    },
    {
      label: this.$t('vigneron-online.shop-content.title')
    }]
  }

  mounted() {
    enableIbox(this.$refs.shopContent as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.shopContent as HTMLElement);
  }

}
</script>