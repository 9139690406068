import { toRefs, Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

interface ThemeInput {
  path : string
}

export function useTheme(props:ThemeInput, {emit}:any) { 
  const app = getApp();

  var path = props.path;

  const updatePath = (newPath:string) => {
    path = newPath;
  }

  const cssVariables = [
    {
      key: 'primaryColor',
      css: '--primary-color',
      default: '#b880ff'
    },
    {
      key: 'secondaryColor',
      css: '--secondary-color',
      default: '#C89CFF'
    },
    {
      key: 'headerLinksColor',
      css: '--header-links-color',
      default: '#444'
    },
    {
      key: 'headerLinksHoverColor',
      css: '--header-links-hover-color',
      default: '#C89CFF'
    },
    {
      key: 'headerLinksActiveColor',
      css: '--header-links-active-color',
      default: '#C89CFF'
    },
    {
      key: 'headerDarkLinksColor',
      css: '--header-dark-links-color',
      default: '#F7F7F7'
    },
    {
      key: 'headerDarkLinksHoverColor',
      css: '--header-dark-links-hover-color',
      default: '#C89CFF'
    },
    {
      key: 'headerDarkLinksActiveColor',
      css: '--header-dark-links-active-color',
      default: '#C89CFF'
    },
    {
      key: 'headerBackgroundColor',
      css: '--header-background-color',
      default: '#FFFFFF'
    },
    {
      key: 'headerDarkBackgroundColor',
      css: '--header-dark-background-color',
      default: '#333333'
    },
    {
      key: 'headerBorderColor',
      css: '--header-border-color',
      default: '#EEE'
    },
    {
      key: 'bodyTextColor',
      css: '--body-text-color',
      default: '#444'
    },
    {
      key: 'bodyBackgroundColor',
      css: '--body-background-color',
      default: 'inherit'
    },
    {
      key: 'contentLinksColor',
      css: '--content-links-color',
      default: '#444'
    },
    {
      key: 'contentLinksHoverColor',
      css: '--content-links-hover-color',
      default: '#222'
    },
    {
      key: 'contentBackgroundColor',
      css: '--content-background-color',
      default: '#FFF'
    },
    {
      key: 'footerBorderColor',
      css: '--footer-border-color',
      default: '#999'
    },
    {
      key: 'footerTextColor',
      css: '--footer-text-color',
      default: '#555'
    },
    {
      key: 'footerLinksColor',
      css: '--footer-links-color',
      default: '#333'
    },
    {
      key: 'footerLinksHoverColor',
      css: '--footer-links-hover-color',
      default: '#555'
    },
    {
      key: 'footerLinksActiveColor',
      css: '--footer-links-active-color',
      default: '#555'
    },
    {
      key: 'footerBackgroundColor',
      css: '--footer-background-color',
      default: '#DDD'
    }
  ]

  var emptyTheme:any = {
    variables: {},
    darkMode: false
  };
  for(var variable of cssVariables) {
    emptyTheme.variables[variable.key] = null;
  }

  const update = (theme:any) => {
    var input:any = {
      theme: theme
    }
    var options:api.ApiVueOptions =  {
      app: app
    }
    var computedPath = path + '/update';
    return api.postAPI(computedPath, input, options);
  }

  const download = () => {
    var options:api.ApiVueOptions =  {
      app: app
    }

    var computedPath = path + '/download';
    return api.getAPI(computedPath, options);
  }

  const upload = (file:File) => {
    var options:api.ApiVueOptions =  {
      app: app
    }

    var formData = new FormData();
    if(file != null) {
      formData.append("file",file, file.name);  
    }

    var computedPath = path + '/upload';
    
    return api.postAPIFormData(computedPath, formData, options, {});
  }

  return {
    cssVariables,
    emptyTheme,
    update,
    download,
    upload,
    updatePath
  }
  
}