<template>
  <div class="ibox theme">
    <div class="ibox-title">
      <h2>{{ $t('cms.site.theme.title') }}</h2>
    </div>
    <div class="ibox-content p-md">

      <LogoAndBanner></LogoAndBanner>

      <div class="hr-line-dashed"></div>
      
      <CssVariables></CssVariables>
    
    </div>
  </div>
</template>

<style>

</style>



<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import CssVariables from './forms/CssVariables.vue';
import LogoAndBanner from './forms/LogoAndBanner.vue';

export default defineComponent({
    props: {
        
    },
    components: {
      CssVariables,
      LogoAndBanner
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      return { 
        
      }
    }
})
</script>